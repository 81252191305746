import React, { useEffect, useState } from "react";
import ProductLists from "../../components/shopping/ProductLists";
import { ApiHook } from "../../hooks/apiHook";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Terms from "../../components/shopping/Terms";
import { useSelector } from "react-redux";

const ShoppingLayout = () => {
  const isSubscribed = useSelector((state) => state?.dashboard?.appLayout?.validSubscription);
  const { t } = useTranslation();
  const items = ApiHook.CallRepurchaseItems();
  const terms = ApiHook.CallRepurchaseTerms();
  
  console.log("====terms===", terms?.data)
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (isSubscribed != undefined) setShow(!isSubscribed)
  }, [isSubscribed])

  const handleClose = () => {
    setShow(false)

  };
  return (
    <>
      <div className="page_head_top">{t("shopping")}</div>
      <div className="ewallet_top_btn_sec">
        <div className="row justify-content-between">
          <div className="col-md-4 text-end">
            <div className="dropdown btn-group top_right_pop_btn_position">
              <Link to={"/repurchase-report"} className="top_righ_pop_btn">
                {t("repurchase_report")}
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Terms data={terms?.data} show={show} handleClose={handleClose} />
      <ProductLists products={items?.data} />
    </>
  );
};

export default ShoppingLayout;
