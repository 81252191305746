import React, { useState } from "react";
import { Button, Form, Offcanvas } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ApiHook } from "../../hooks/apiHook";
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import { Segmented } from 'antd';

const MailCompose = ({ showCompose, setShowCompose, setPage, setApiTab, activeTab }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { register, trigger, watch, setValue, setError, reset, clearErrors, formState: { errors } } = useForm({
    defaultValues: {
      type: "individual",
      username: "",
      subject: "",
      message: "",
    }
  });
  const formValues = watch();
  const [composeMailPayload, setComposeMailPayload] = useState({
    type: "individual",
    message: "",
  });

  const modules = {
    toolbar: [
      [{ list: "ordered" }, { list: "bullet" }],
      ["bold", "italic", "underline"],
      [{ align: [] }],
    ],
  };

  const composeInternalMailMutation = ApiHook.CallSendInternalMail(composeMailPayload);  // API

  const handleComposeMail = async (e) => {
    e.preventDefault();
    let isValid;
    isValid = await trigger();
    if (isValid) {
      if (!formValues.message.trim()) {
        setError("message", { type: "required", message: t("this_field_is_required") });
        return;
      }
      composeInternalMailMutation.mutateAsync(formValues, {
        onSuccess: (res) => {
          if (res?.status) {
            toast.success(t(res?.data));
            setShowCompose(false);
            setApiTab(activeTab);
            setPage(1);
            queryClient.invalidateQueries({ queryKey: [activeTab] });
            setComposeMailPayload({
              message: "",
            });
            reset();
          } else {
            const code = res?.code
            if (code) {
              if (code === 1043 || code === 1070) {
                setError("username", { message: res.description })
              }
              toast.error(t(res?.description));
            }
          }
        },
      });
    }
  };

  const handleMailContentChange = (value) => {
    setValue("message", value);
    setComposeMailPayload((prevData) => ({
      ...prevData,
      message: value,
    }));
    clearErrors("message");
  };


  const handleTypeChange = (value) => {
    setValue("type", value)
    setComposeMailPayload((prevData) => ({
      ...prevData,
      type: value
    }))
  }

  const mailTypes = [
    { label: t("individual"), value: "individual" },
    { label: t("team"), value: "team" },
  ]

  const handleClose = () => {
    setShowCompose(false);
    reset({
      type: "individual",
      username: "",
      subject: "",
      message: ""
    });
    setComposeMailPayload({
      type: "individual",
      message: "",
    })
  };
  return (
    <>
      <Offcanvas
        id="composemail"
        show={showCompose}
        onHide={() => setShowCompose(false)}
        placement="end"
        style={{ backgroundColor: "white" }}
      >
        <Offcanvas.Header closeButton onHide={handleClose}>
          <Offcanvas.Title>{t("new_mail")}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <main>
            <Form>
              <Segmented style={{ marginBottom: "10px" }} options={mailTypes} value={composeMailPayload.type} block onChange={handleTypeChange} />

              {formValues.type === "individual" &&
                <Form.Group className="mb-3" controlId="to">
                  <Form.Label>{t('to')}</Form.Label>
                  <Form.Control
                    {...register("username", { required: { value: 1, message: ("this_field_is_required") } })}
                    onBlur={async () => await trigger("username")}
                    type="text"
                    placeholder={t("username")}
                  />
                  {errors["username"] && (
                    <span className="error-message-validator">
                      {t(errors["username"].message)}
                    </span>
                  )}
                </Form.Group>
              }
              <Form.Group className="mb-3" controlId="to">
                <Form.Label>{t('subject')}</Form.Label>
                <Form.Control
                  {...register("subject", {
                    required: { value: true, message: ("this_field_is_required") }
                  })}
                  onBlur={async () => await trigger("subject")}
                  type="text"
                  placeholder={t('subject')}
                  maxLength={67} // Set the maximum length to 100 characters
                />
                {errors["subject"] && (
                  <span className="error-message-validator">
                    {t(errors["subject"].message)}
                  </span>
                )}
              </Form.Group>
              <Form.Group className="mt-4" style={{ height: "230px" }}>
                <ReactQuill
                  value={composeMailPayload.message}
                  onChange={handleMailContentChange}
                  modules={modules}
                  style={{ height: "200px" }}
                  maxLength={3000} // Set the maximum length to 3000 characters
                />
              </Form.Group>
              {errors["message"] && (
                <span style={{ paddingTop: "15px" }} className="error-message-validator">
                  {t(errors["message"].message)}
                </span>
              )}
              <Form.Group className="mt-4">
                <Button
                  variant="success"
                  type="submit"
                  onClick={handleComposeMail}
                  disabled={composeInternalMailMutation.status === "loading"}
                >
                  {t("send")}
                </Button>
              </Form.Group>
            </Form>
          </main>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default MailCompose;