import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import VideoCard from '../../components/Videos/VideoCard'
import { ApiHook } from '../../hooks/apiHook'
import { useTranslation } from 'react-i18next';

export default function VideoList() {
    const { t } = useTranslation();

    const [page, setpage] = useState(1)
    const limit = 8

    const data = ApiHook.CallVideoList(page, limit)
    const handleNext = () => {
        if (data.data.count > (page * limit)) {
            setpage(page + 1)
        }
    }
    const handlePrev = () => {
        if (page > 1) {
            setpage(page - 1)
        }
    }
    return (
        <>
            <div className="page_head_top">{t("videos")}</div>

            <div className='row'>
                <p>{`showing ${((page - 1) * limit) + 1} to ${(page * limit) < data?.data?.count ? (page * limit) : data?.data?.count} of ${data?.data?.count} videos`}</p>
                {data?.data?.rows.map((item) => {
                    return (<div className='col-md-3' style={{ padding: "10px" }}>
                        <VideoCard
                            title={item.title}
                            description={item.description}
                            videoUrl={item.videoUrl}
                            image={item.image}
                        />
                    </div>)
                })}

            </div>
            <div>
                <div class="bottom-div">
                    <Button style={{ borderRadius: "50%" }} disabled={(page <= 1)} variant='secondary' onClick={handlePrev}>
                        <i class="fa-solid fa-chevron-left"></i>
                    </Button>
                    {" "}
                    <Button style={{ borderRadius: "50%" }} disabled={(page * limit) >= (data?.data?.count)} onClick={handleNext}>
                        <i class="fa-solid fa-chevron-right"></i>
                    </Button>
                </div>
            </div>
        </>

    )
}
