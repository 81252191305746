import React from 'react'
import { Card } from 'react-bootstrap'
import videoIcon from '../../assests/images/video-icon.jpg'

export default function VideoCard({ title, description, videoUrl, image }) {
    const handleWatch = (url) => {
        window.open(url, "_blank", "noopener,noreferrer");
    };
    return (
        <div>
            <Card className='video-card' style={{ padding: "10px", maxHeight: "400px", minHeight: "400px" }} onClick={() => handleWatch(videoUrl)}>
                <Card.Img style={{ padding: "0px", borderRadius: "5px", height: "150px", width: "200px" }} variant="top" src={image ?? videoIcon} />
                <Card.Body style={{ textAlign: "center" }}>
                    <strong><Card.Title>{title}</Card.Title></strong>
                    <hr />
                    <Card.Text style={{
                        maxHeight: "70px",
                        minHeight: "70px",
                        overflow: "auto",
                    }}>
                        {description}
                    </Card.Text>
                    {/* <hr /> */}
                    {/* <Button variant="primary" onClick={() => handleWatch(videoUrl)}>watch</Button> */}
                </Card.Body>
            </Card>
        </div>
    )
}
