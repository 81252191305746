import React, { useState } from 'react'
import { ApiHook } from '../../hooks/apiHook';
import Terms from '../../components/shopping/Terms';

export default function ExpiredForm() {
  const [show, setShow] = useState(true);
  const handleClose = () => {
    setShow(false)
  };
  const terms = ApiHook.CallRepurchaseTerms();

    return (
        <>
        <Terms data={terms?.data} show={show} handleClose={handleClose} />
        </>

    )
}
