import { t } from 'i18next';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function Terms({ data, show, handleClose }) {
    // const [showAll, setShowAll] = useState(false)
    // const showMore = () => {
    //     setShowAll(!showAll)
    // }
    const goToShopping = () => {
        window.open(data.terms, "_blank", "noopener,noreferrer");
    }
    return (
        <>
            <Modal show={show} /* onHide={handleClose} */ backdrop="static" keyboard={false}>
                <Modal.Header >
                    <Modal.Title>Terms And Conditions</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data?.overview}
                    <br />
                    {/* {showAll &&
                        <>
                            <hr />
                            {data.terms}
                        </>
                    } */}
                </Modal.Body>
                <Modal.Footer>
                    {/* <Button variant="primary" onClick={showMore}>
                        {!showAll ? <i class="fa-solid fa-angles-right"></i> : <i class="fa-solid fa-angles-up"></i>}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button> */}
                    <Button variant="primary" onClick={goToShopping}>
                        Pay Fee
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}
